@mixin custom-scrollbar {
  $width: 8px;
  overflow: auto;

  // For Firefox only
  scrollbar-width: thin;

  &::-webkit-scrollbar-track
  {
    border-radius: $width;
    background-color: $color-light-2;
  }

  &::-webkit-scrollbar
  {
    border-radius: $width;
    width: remSize(4);
    height: remSize(5);
    background-color: $color-light-2;
  }

  &::-webkit-scrollbar-thumb
  {
    border-radius: $width;
    background-color: $color-dark-2;
  }
}

@mixin custom-text-field {
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  border: remSize(1) solid $color-light-0;
  border-radius: remSize(8);
  color: $color-dark-1;

  &:focus {
    outline: none;
    border: remSize(1) solid $color-blue-1;
    border-radius: remSize(8);
  }

  &::placeholder {
    color: $color-dark-3;
  }
}

@mixin custom-select-field {
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  border: remSize(1) solid $color-light-0;
  border-radius: remSize(8);
  background-color: $color-light-4;
  color: $color-dark-1;

  &:focus {
    outline: none;
    border: remSize(1) solid $color-blue-1;
    border-radius: remSize(8);
  }
}

@mixin rounded-elevation {
  box-shadow: 0 0 remSize(1) rgba(40, 41, 61, 0.04), 0 remSize(2) remSize(4) rgba(96, 97, 112, 0.16);
  border-radius: remSize(8);

  &:hover {
    box-shadow: 0 remSize(2) remSize(4) rgba(40, 41, 61, 0.04), 0 remSize(8) remSize(16) rgba(96, 97, 112, 0.16);
  }
}

@mixin angle-up-down-expanders {
  .fa.fa-angle-up {
    display: none;
  }

  &[aria-expanded=true] {

    .fa.fa-angle-up {
      display: inline-block;
    }

    .fa.fa-angle-down {
      display: none;
    }
  }
}

@mixin responsive-max-width {
  max-width: 100%;
  padding: 2rem;

  @media (min-width: $md-start) {
    max-width: 80vw;
    padding: 0;
  }

  @media (min-width: 1400px) {
    max-width: 1200px;
  }
}
