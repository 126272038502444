.demio-registration-form {
  padding: 48px 32px;
  border-radius: 16px;
  box-shadow: 0px -2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.16);

  .demio-form-field-container {
   
    input[type=text], input[type=email] {
      font-size: 20px;
      border: none;
      padding: 36px 20px 16px 20px;
      border-radius: 8px;
      background-color: $color-light-3;
      height: 72px;
      width: 100%;
      margin-bottom: -16px;
    }

    label {
      display: block;
      .Demio-Input-label {
        position: relative;
        top: -52px;
        left: 20px;
      }
    }
  }
}