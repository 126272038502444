div[class^="background-top-"] {
  top: 0;
  z-index: -1;
  position: absolute;
  background-repeat: no-repeat;
  // background-size: cover;
  background-position: top center;
  height: 100%;
  width: 100vw;
}

.background-top-gray-gray {
  background-image: url("/assets/img/background/background-top-gray-gray.svg");
}

.background-top-gray {
  background-image: url("/assets/img/background/background-top-gray.svg");
}

.background-top-gray-blue {
  background-image: url("/assets/img/background/background-top-gray-blue.svg");
}

.background-top-gray-green {
  background-image: url("/assets/img/background/background-top-gray-green.svg");
}


.dark-heading-background {
  background-image: url("/assets/img/background/dark-heading-background.svg");
  background-size: cover;
}
