@import "variables";

body {
  overflow-x: hidden;
  padding-top: $navbar-height;

  .notification-bar {
    color: $color-light-1;
    background-color: $color-everglade-lighter !important;
    height: $notification-bar-height;

    @include media-breakpoint-down(sm) {
      height: $notification-bar-height * 2;
    }
  }

  #main-header {
    top: $notification-bar-height;

    @include media-breakpoint-down(sm) {
      top: $notification-bar-height * 2;
    }

    &.page-scrolled {
      top: 0px;
      transition: top 100ms;
    }
  }

  .navbar-offset {
    margin-top: -$navbar-height;
    padding-top: $navbar-height;
  }

}

html {
  scroll-behavior: smooth;
}

:target:before {
  content: "";
  display: block;
  height: 180px;
  margin: -180px 0 0;
}

section {
  padding-top: 4.5rem;
  padding-bottom: 4.5rem;

  @include media-breakpoint-down(md) {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
}

.section-icon-padding {
  padding-top: 66px;

  @include media-breakpoint-down(md) {
    padding-top: 0px;
  }
}

.elevated-container {
  background-color: $color-light-4;
  @include rounded-elevation;
}

.single-section-full-height {
  min-height: calc(100vh - 600px);
}

.display-block {
  display: block;
}