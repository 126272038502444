@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins/breakpoints";


@import url('https://fonts.googleapis.com/css?family=Inter:300,400,500,600,700,800&display=swap');

html, body {
  font-size: 16px;
}

.text-blockquote {
  font-weight: $typography-weight-semi-bold;
  font-size: remSize(24);
  line-height: remSize(32);

  @media (max-width: $md-max) {
    font-size: remSize(20);
    line-height: remSize(26);
  }

  @media (max-width: $xs-max) {
    font-size: remSize(16);
    line-height: remSize(21);
  }
}

.text-caption {
  @include font-size(0.75rem);
}

.text-underline-green {
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: 6px;
  text-underline-offset: 6px;
  text-decoration-color: $color-shamrock-main;
}


.text-explainer {
  @include font-size(1rem);
  font-weight: 400;
}

.text-feature-explainer {
  font-size: remSize(20);
  font-weight: 400;

  @media (max-width: $md-max) {
    font-size: calc(0.85rem + 0.8vw);
  }
}


.text-tag {
  font-size: 0.9rem;
  border-radius: 0.5rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  font-weight: $typography-weight-bold;

  &.text-tag-green {
    color: $color-green-0;
    border: 1px solid $color-green-2;
    background-color: $color-green-4;
  }

  &.text-tag-blue {
    color: $color-blue-0;
    border: 1px solid $color-blue-2;
    background-color: $color-blue-4;
  }
}

.text-section-title {
  font-size: 1rem;
  letter-spacing: 0.15rem;
  text-transform: uppercase;
}

.text-blockquote-small {
  font-weight: $typography-weight-semi-bold;
  font-size: remSize(20);
  line-height: remSize(28);
}

.cursor-pointer {
  cursor: pointer;
}

b, strong {
  font-weight: 600;
}
