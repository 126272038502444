$halo-offset-desktop: 24px;
$halo-offset-mobile: 8px;

@mixin halo {
  border-radius: 12px;

  img, iframe {
    margin-top: $halo-offset-desktop;
    margin-bottom: -$halo-offset-desktop;
  }

  @include media-breakpoint-down(md) {
    img, iframe {
      margin-top: $halo-offset-mobile;
      margin-bottom: -$halo-offset-mobile;
    }
  }
}

@mixin halo-right {
  @include halo;

  margin-left: $halo-offset-desktop;

  img, iframe {
    margin-left: -$halo-offset-desktop;
  }

  @include media-breakpoint-down(md) {
    margin-left: $halo-offset-mobile;

    img, iframe {
      margin-left: -$halo-offset-mobile;
    }
  }
}

@mixin halo-left {
  @include halo;

  text-align: right;

  margin-right: $halo-offset-desktop;

  img, iframe {
    text-align: right;
    margin-right: -$halo-offset-desktop;
    margin-left: $halo-offset-desktop;
  }

  @include media-breakpoint-down(md) {
    margin-right: $halo-offset-mobile;

    img, iframe {
      margin-right: -$halo-offset-mobile;
      margin-left: $halo-offset-mobile;
    }
  }
}

.blue-halo-left {
  @include halo-left;
  background-color: #E5F6FF;
}

.green-halo-left {
  @include halo-left;
  background-color: #E3FFF1;
}

.blue-halo-right {
  @include halo-right;
  background-color: #E5F6FF;
}

.green-halo-right {
  @include halo-right;
  background-color: #E3FFF1;
}

