#apmOnboardingModal {
  iframe {
    padding-bottom: 2rem;
  }

  .modal-content {
    min-height: 890px;
    max-width: 660px;
    border-radius: 1rem;
    border: none;
    overflow: hidden;
  }
}
