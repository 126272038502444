// DESIGN COLORS

// - PRIMARY
// -- shamrock
$color-shamrock-darker: #23A966;
$color-shamrock-main: #3BC882;
$color-shamrock-lighter: #39D98A;
// -- everglade
$color-everglade-darker: #172924;
$color-everglade-main: #223C37;
$color-everglade-lighter: #376C66;

// - SECONDARY
// -- azure radiance
$color-azure-radiance-darker: #007EC4;
$color-azure-radiance-main: #0E95F6;
$color-azure-radiance-lighter: #60B9FA;

// - TEXT AND BACKGROUND
// -- dark
$color-dark-0: #1C1C28;
$color-dark-1: #404153;
$color-dark-2: #555770;
$color-dark-3: #8F90A6;
$color-dark-4: #C7C9D9;
$color-dark-outline: #8F90A6;
$color-dark-background: #2c2c31;
// -- light
$color-light-0: #DEDFE9;
$color-light-1: #E9EAF4;
$color-light-2: #F4F4FB;
$color-light-3: #FBFBFD;
$color-light-4: #FFFFFF;
$color-light-outline: #E4E4EB;

// - STATUS AND CUSTOM COLORS
// -- red
$color-red-0: #E63535;
$color-red-1: #FF3B3B;
$color-red-2: #FF5C5C;
$color-red-3: #FF8080;
$color-red-4: #FFE6E6;
$color-red-outline: #FF3B3B;
// -- green
$color-green-0: #23A966;
$color-green-1: #3BC882;
$color-green-2: #39D98A;
$color-green-3: #57EBA1;
$color-green-4: #E3FFF1;
$color-green-outline: #06C270;
// -- blue
$color-blue-0: #007EC4;
$color-blue-1: #0E95F6;
$color-blue-2: #60B9FA;
$color-blue-3: #9DD8F9;
$color-blue-4: #E5F6FF;
$color-blue-outline: #008FF7;
// -- yellow
$color-yellow-0: #E6B800;
$color-yellow-1: #FFCC00;
$color-yellow-2: #FDDD48;
$color-yellow-3: #FDED72;
$color-yellow-4: #FFFEE6;
$color-yellow-outline: #FFCC00;
// -- orange
$color-orange-0: #E67A00;
$color-orange-1: #FF8800;
$color-orange-2: #FDAC42;
$color-orange-3: #FCCC75;
$color-orange-4: #FFF8E6;
$color-orange-outline: #FF8800;
// -- teal
$color-teal-0: #00B7C4;
$color-teal-1: #00CFDE;
$color-teal-2: #73DFE7;
$color-teal-3: #A9EFF2;
$color-teal-4: #E6FFFF;
$color-teal-outline: #00CFDE;
