@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins/breakpoints";

.btn-block-sm {
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}

.btn-outline-secondary {
  border-color: #E4E4EB;
  background-color: #FFFFFF;

  &:hover {
    color:inherit;
    border-color: #C7C9D9;
    background-color: #FAFAFC;
  }

  &:focus, &:active {
    color:inherit !important;
    border-color: #C7C9D9 !important;
    background-color: #EBEBF0 !important;
  }
}

.btn-semi-large {
  padding: 16px 32px;
  font-size: remSize(16);
  line-height: 1;
}

.btn-cta {
  font-size: 18px;
  font-weight: 600;
  padding: 0.5rem 1.5rem;
}