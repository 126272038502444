@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins/breakpoints";

a.nav-link {
  @include font-size(1.125rem);
  font-weight: 500;
  letter-spacing: 0.3px;
  padding: 0.5rem 1rem !important;

  &:focus-visible {
    outline: none;
    border: none;
  }

  &:not(.btn) {
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    color: $color-dark-1;
  }

  @include media-breakpoint-down(md) {
    margin: remSize(8) remSize(8);
    font-size: remSize(18);
  }

  &.dropdown-toggle::after {
    content: "";
    display: inline-block;
    width: 8px;
    height: 8px;
    border-top: 2.5px solid $color-dark-1;
    border-left: 2.5px solid $color-dark-1;
    border-right: none;
    transition: 0.2s;
    transform: translate(8px, 1px) rotate(-135deg);
  }
}

.navbar-nav {
  .navbar-item.dropdown.show {
    @include media-breakpoint-up(lg) {
      a.nav-link {
        color: $color-dark-1;
      }

      a.nav-link::after {
        transform: translate(8px, 5px) rotate(45deg);
      }
    }
  }
}

.nav-submenu-container {
  max-width: 550px;
  min-width: 550px;
  color: $color-dark-3;

  .section-title {
    font-weight: 600;
    color: $color-dark-2;
  }

  .nav-submenu-item {
    padding: 0.75rem;
    border-radius: 0.5rem;
    text-decoration: none;
    color: $color-dark-3;

    &:hover {
      color: $color-dark-1;
      background-color: $color-light-2;
    }

    .item-title {
      font-weight: 600;
      color: $color-dark-2;
      font-size: 1rem;
    }

    .item-explainer {
      color: $color-dark-3;
    }   
  }
}

.navbar.mobile-expanded {
   background-color: $color-light-3 !important;
  max-height: 100%;
  overflow: auto;

  .notification-bar + & {
    max-height: calc(100% - 36px);
  }
}

.dropdown-menu.show {
  margin-top: -1px;
  border: none;
  @include rounded-elevation;
}
