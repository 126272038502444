ul.icon-list {
  list-style: none;
  padding-left: 0px;

  li {
    padding: 8px 0px 8px 24px;
    background-size: 24px 24px;
    background-position: left center;
    background-repeat: no-repeat;
  }
}

ul.check-list {
  li {
    background-image: url("/assets/img/pages/pricing/green-check-mark.svg");
    background-size: 1.5rem;
    padding-left: 3rem;
  }
}