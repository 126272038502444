
$base-font-size: 16px;
$navbar-height: 80px;
$docs-navbar-height: 64px;
$notification-bar-height: 36px;

// DESIGN TYPOGRAPHY
$typography-primary-font: "Inter";
$typography-weight-bold: 700;
$typography-weight-semi-bold: 600;
$typography-weight-medium: 500;
$typography-weight-regular: 400;

$xs-max: 575px;
$sm-start: 576px;
$sm-max: 767px;
$md-start: 768px;
$md-max: 991px;
$lg-start: 992px;
$lg-max: 1199px;
$xl-start: 1200px;