a.arrow-link {
  font-weight: 600;

  &:after {
    content: "arr";
    color: transparent;
    background-image: url("/assets/img/icons/directional/arrow-right-azure-radiance.svg");
    background-repeat: no-repeat;
    background-position: 50%;
  }

  &:hover:after {
    background-image: url("/assets/img/icons/directional/arrow-right-azure-radiance-darker.svg");
  }
}

a.plain-link {
  font-size: 18px;
  font-weight: 600;
  color: $color-dark-2;
  text-decoration: none;

  &:hover {
    color: $color-dark-3;
  }
}

a.no-decoration {
  color: inherit;
  text-decoration: none;

  @include hover() {
    color: inherit;
    text-decoration: none;
  }
}

a.grow-to-container {
  span {
    top: 0px;
    left: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
  }
}