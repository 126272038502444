@import "colors";

.outline-alert {
  border-radius: remSize(8);
  border: remSize(2) solid $color-shamrock-main;
  padding: 1rem 1rem 0rem 1rem;
  margin-bottom: 1rem;

  &.outline-alert-info {
    border: remSize(2) solid $color-blue-1;
  }
  &.outline-alert-warning {
    border: remSize(2) solid $color-yellow-1;
  }
  &.outline-alert-danger {
    border: remSize(2) solid $color-red-1;
  }
}
